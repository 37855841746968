import React from "react";
import Layout from "../components/Layout";

export default function Investing() {
    return (
        <Layout breadcrumbs={["Investing"]}>
            <section className="post">
                <div>
                    <h2>Investing & Advising</h2>
                    <p>
                        I'm endlessly fascinated by where technology is going and how it'll influence business, culture 
                        and society. I'm also lucky enough to help advise high-growth tech startups using frameworks and 
                      experiences I've learned in my career working at high-output companies. Some things I help with as an investor and advisor include:
                    </p>
                    <p><ul>
                        <li>Product management & design</li>
                        <li>Corp dev (M&A)</li>
                        <li>Biz ops</li>
                        <li>Growth-stage culture & operating cadence</li>
                        <li>Goal setting & planning</li>
                        <li>Org design</li>
                        <li>Recruiting</li>
                        <li>P&L management</li>
                        <li>Fundraising</li>
                    </ul></p>
                </div>
            </section>  
    </Layout>

  )
}
